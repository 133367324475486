import revive_payload_client_vXP5nXhiLf from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_76TMK6qNGI from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_XAUWk2UO1Z from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_Ub7jFNgAqV from "/app/packages/web/modules/sentry/runtime/sentry.client.ts";
import query_Ux2Qt90xcP from "/app/packages/web/plugins/query.ts";
import trpc_client_3tK3TWUrV0 from "/app/packages/web/plugins/trpc.client.ts";
import payload_client_66DdzhcSeF from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_T8BdL1VDqE from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wsy5T4VTHF from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_wMb51xIoT1 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_GQ2fDxPEHT from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3sRwIOYoFQ from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.3_typescript@5.6.2_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/packages/web/.nuxt/components.plugin.mjs";
import prefetch_client_tqmXSbzFmu from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@18.19.50_encoding@0.1.13_eslint@8.57.1_ioredis@_x5wneox5nakkfvco42wfwxvbta/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_FDenFFtuUt from "/app/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.4_magicast@0.3.5_rollup@4.21.3_vue@3.5.6_typescript@5.6.2__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import slideovers_Yq7a9U7vHc from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@swc+core@1.7.26_@typ_wu7lf2a24tcmagvx7jllo2g6za/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_MC60YPdWEc from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@swc+core@1.7.26_@typ_wu7lf2a24tcmagvx7jllo2g6za/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ac8RROlpmE from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.7_magicast@0.3.5_rollup@4.21.3_ts-node@10.9.2_@swc+core@1.7.26_@typ_wu7lf2a24tcmagvx7jllo2g6za/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_f31VDMrr4w from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_SBr7Nb8P5A from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.3_vite@5.4.6_@types+node@18.19.50_terser@5.32.0___hogbxzzfk5cocuxxhv6zqgefsu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import marker_io_client_3vX6FuptAi from "/app/packages/web/plugins/marker-io.client.ts";
export default [
  revive_payload_client_vXP5nXhiLf,
  unhead_76TMK6qNGI,
  router_XAUWk2UO1Z,
  sentry_client_Ub7jFNgAqV,
  query_Ux2Qt90xcP,
  trpc_client_3tK3TWUrV0,
  payload_client_66DdzhcSeF,
  navigation_repaint_client_T8BdL1VDqE,
  check_outdated_build_client_wsy5T4VTHF,
  view_transitions_client_wMb51xIoT1,
  chunk_reload_client_GQ2fDxPEHT,
  plugin_vue3_3sRwIOYoFQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_tqmXSbzFmu,
  plugin_client_FDenFFtuUt,
  slideovers_Yq7a9U7vHc,
  modals_MC60YPdWEc,
  colors_ac8RROlpmE,
  plugin_client_f31VDMrr4w,
  plugin_SBr7Nb8P5A,
  marker_io_client_3vX6FuptAi
]